const initialFailsafe = {
  id: '',
  name: '',
  category: '',
  subcategory_1: '',
  subcategory_2: '',
  assigned_user: '',
  description: '',
  value: ''
};

export const initialFailsafeList = [
  {
    id: '132465',
    name: 'Test 1',
    category: 'dashboard',
    subcategory_1: 'opportunity',
    subcategory_2: 'users',
    assigned_user: 'kamlesh thavani',
    description: 'Test Description',
    value: 'test value'
  }
];

export default initialFailsafe;

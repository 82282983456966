import fieldLabel from 'assets/constants/fieldLabel';
import { missingField } from 'assets/validation-template';
import { isEmpty } from 'helpers/misc-helper';
import { ObjectType } from 'types';
import { ErrorValidation } from 'types/error-types';
import { FailsafeEntity } from 'types/failsafe-types';

export const validateFailsafe = (data: FailsafeEntity): ObjectType => {
  let validation: ErrorValidation = {};

  if (isEmpty(data.name)) {
    validation = {
      ...validation,
      name: [missingField(fieldLabel.name)]
    };
  }

  if (isEmpty(data.value)) {
    validation = {
      ...validation,
      value: [missingField(fieldLabel.value)]
    };
  }

  if (isEmpty(data.category)) {
    validation = {
      ...validation,
      category: [missingField(fieldLabel.category)]
    };
  }

  if (isEmpty(data.subcategory_1)) {
    validation = {
      ...validation,
      subcategory_1: [missingField(`${fieldLabel.subCategory} 1`)]
    };
  }

  if (isEmpty(data.subcategory_2)) {
    validation = {
      ...validation,
      subcategory_2: [missingField(`${fieldLabel.subCategory} 2`)]
    };
  }

  return {
    status: Object.keys(validation).length === 0,
    errors: validation
  };
};

import { FailsafeFilterEntity, FailsafeListEntity } from 'types/failsafe-types';
import { GetListResponse } from 'types/api-response-types';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import DataGrid from 'components/data-grid/hoc-datagrid';
import defaultSort from 'assets/list/account/default-sort';
import failsafeService from 'services/failsafe-sercvice';
import Filters from './filters';
import listQueryString, { prepareSort } from 'helpers/query-string-helper';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useRouteName from 'pages/route-outlet-context';
import failSafeColumns from 'assets/list/failsafe/columns';
import ModalComponent from 'components/modal';
import FailSafeCreate from '../failsafe-create';
import { isEmpty } from 'helpers/misc-helper';
import initialConfirmModal from 'state/confirm-box/initial-confirm-box';
import ConfirmBox, {
  ConfirmModalProps
} from 'components/confirm-box/confirm-box';
import fieldLabel from 'assets/constants/fieldLabel';
import MessageDeleteRecordConfirmation from 'components/delete-warnign-message';
import { toastError, toastSuccess } from 'event/toast-event';

const FailSafeList = ({ routeTag }: { routeTag?: string }) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const { setRouteName } = useRouteName();
  const [activeUser, setActiveUser] = useState<string | null>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<FailsafeFilterEntity>({ name: '' });
  const [confirmModal, setConfirmModal] =
    useState<ConfirmModalProps>(initialConfirmModal);

  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);

  const updateFilter = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFilter((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const getFailsafes = async (
    paginationModel: GridPaginationModel,
    sortModel: GridSortModel
  ): Promise<GetListResponse<FailsafeListEntity>> => {
    const queryString = listQueryString({
      pagination: paginationModel,
      sort: prepareSort(sortModel, defaultSort),
      filter: filter
    });

    setIsLoading(true);

    const result = await failsafeService.getList(queryString);

    setIsLoading(false);
    return result;
  };
  const onEdit = (id: string) => setActiveUser(id);

  const onDelete = (data: FailsafeListEntity) => {
    setConfirmModal({
      open: true,
      title: `Remove ${fieldLabel.defaultValues}`,
      text: MessageDeleteRecordConfirmation(
        data.name,
        fieldLabel.defaultValues
      ),

      proceed: async () => {
        const result = await failsafeService.delete(data.id);

        if (result.isError) {
          toastError(result.errorMessage.message);
          return;
        }

        toastSuccess('Failsafe removed successfully');
        setConfirmModal(initialConfirmModal);
        setIsFilterChanged(true);
      },
      cancel: () => {
        setConfirmModal(initialConfirmModal);
      }
    });
  };

  useEffect(() => {
    setRouteName(routeTag ?? '');
  }, []);

  return (
    <>
      <Filters
        filter={filter}
        updateFilter={updateFilter}
        setIsFilterChanged={setIsFilterChanged}
        setFilter={setFilter}
        isLoading={isLoading}
        addNew={() => {
          setActiveUser(null);
        }}
      />

      <DataGrid<FailsafeListEntity>
        getData={getFailsafes}
        tableColumns={failSafeColumns({ onEdit, onDelete })}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        initialLoad={initialLoad}
        setInitialLoad={setInitialLoad}
        isLoading={isLoading}
      />

      {activeUser !== undefined && (
        <ModalComponent
          Component={FailSafeCreate}
          data={{
            routeTag: isEmpty(activeUser) ? 'record-add' : 'record-edit',
            failsafe_id: activeUser,
            onCancel: (reloadUsers: boolean) => {
              setActiveUser(undefined);
              if (reloadUsers) setIsFilterChanged(!isFilterChanged);
            }
          }}
          onClose={() => setActiveUser(undefined)}
          loading={false}
          size="lg"
        />
      )}

      {confirmModal.open && <ConfirmBox {...confirmModal} />}
    </>
  );
};

export default FailSafeList;

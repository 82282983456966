import {
  GridColDef,
  GridDeleteIcon,
  GridRenderCellParams
} from '@mui/x-data-grid';
import DateUtility from 'helpers/date-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import { BrokerageUserListEntity } from 'types/user';
import UnitButtonIcon from 'components/form/button-icon';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { FailsafeEntity, FailsafeListEntity } from 'types/failsafe-types';

const failSafeColumns = ({
  onEdit,
  onDelete
}: {
  onEdit: (val: string) => void;
  onDelete: (val: FailsafeListEntity) => void;
}): GridColDef[] => [
  {
    headerName: 'Action',
    field: 'action',
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams<FailsafeListEntity>) => (
      <>
        <UnitButtonIcon Icon={EditIcon} onClick={() => onEdit(row.id)} />
        <IconButton
          onClick={() => onDelete(row)}
          sx={{ padding: 0 }}
          color="error"
        >
          <GridDeleteIcon />
        </IconButton>
      </>
    ),
    flex: 0.3,
    minWidth: 120
  },
  {
    headerName: fieldLabel.name,
    field: 'name',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.category,
    field: 'category',
    sortable: true,
    flex: 1
  },
  {
    headerName: `${fieldLabel.subCategory} 1`,
    field: 'subcategory_1',
    sortable: true,
    flex: 1
  },
  {
    headerName: `${fieldLabel.subCategory} 2`,
    field: 'subcategory_2',
    sortable: true,
    flex: 1
  },
  {
    headerName: fieldLabel.value,
    field: 'value',
    sortable: false,
    flex: 1
  },
  {
    headerName: fieldLabel.dateCreated,
    field: 'date_entered',
    sortable: true,
    flex: 0.5,
    valueGetter: ({ row }: GridRenderCellParams<BrokerageUserListEntity>) => {
      return DateUtility.getDateTimeString(row.date_entered);
    }
  }
];

export default failSafeColumns;

import app from 'config/app';
import httpService from './http-service';
import {
  GetListResponse,
  CreateResponse,
  UpdateResponse
} from 'types/api-response-types';
import { FeaturedFlag } from 'types/admin-config';

const featuresService = {
  url: `/${app.crmApiVersion1}/features`,

  getList: async (query: string): Promise<GetListResponse<FeaturedFlag>> => {
    const result = await httpService.list<FeaturedFlag>(
      `${featuresService.url}${query}`
    );

    return result;
  },

  create: async (payload: FormData): Promise<CreateResponse<FeaturedFlag>> => {
    const result = await httpService.post<FeaturedFlag>(
      `${featuresService.url}`,
      payload
    );
    return result;
  },

  update: async (
    id: string,
    payload: FormData
  ): Promise<UpdateResponse<FeaturedFlag>> => {
    const result = await httpService.put<FeaturedFlag>(
      `${featuresService.url}/${id}`,
      payload
    );
    return result;
  },

  delete: async (query: string) => {
    const result = await httpService.del(`${featuresService.url}/${query}`);
    return result;
  }
};

export default featuresService;

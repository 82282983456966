import ClearButton from 'components/form/button-clear';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import SearchButton from 'components/form/button-search';
import StackRow from 'components/stack/stack-row';
import TextUnit from 'components/form/unit-text';
import UnitItem from 'components/form/unit-item';
import { FailsafeFilterEntity } from 'types/failsafe-types';
import AddButton from 'components/form/button-add';

interface FilterProps {
  filter: FailsafeFilterEntity;
  updateFilter: (val: React.ChangeEvent<HTMLInputElement>) => void;
  setIsFilterChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: (val: FailsafeFilterEntity) => void;
  addNew: () => void;
  isLoading?: boolean;
}

const Filters = (props: FilterProps) => {
  const { filter, updateFilter, setIsFilterChanged, setFilter, isLoading } =
    props;

  return (
    <>
      <PaperBox>
        <PaperBoxContent>
          <FormContainer>
            <TextUnit
              label={fieldLabel.name}
              name="name"
              value={filter.name}
              onChange={updateFilter}
              grid={{ xs: 12, sm: 3 }}
            />
            <UnitItem grid={{ xs: 12, sm: 6, md: 3 }}>
              <StackRow isUnitItem>
                <SearchButton
                  onClick={() => {
                    setIsFilterChanged(true);
                  }}
                  disabled={isLoading}
                />
                <ClearButton
                  onClick={() => {
                    setFilter({ name: '' });
                    setIsFilterChanged(true);
                  }}
                />
              </StackRow>
            </UnitItem>
            <UnitItem>
              <AddButton onClick={props.addNew} sx={{ float: 'right' }} />
            </UnitItem>
          </FormContainer>
        </PaperBoxContent>
      </PaperBox>
    </>
  );
};

export default Filters;

import app from 'config/app';
import httpService from './http-service';
import {
  GetListResponse,
  GetResponse,
  CreateResponse,
  UpdateResponse
} from 'types/api-response-types';
import {
  FailsafeEntity,
  FailsafeListEntity,
  FailsafePayloadEntity
} from 'types/failsafe-types';

const failsafeService = {
  url: `/${app.crmApiVersion1}/default_values`,

  getList: async (
    query: string
  ): Promise<GetListResponse<FailsafeListEntity>> => {
    const result = await httpService.list<FailsafeListEntity>(
      `${failsafeService.url}${query}`
    );

    return result;
  },

  get: async (id: string): Promise<GetResponse<FailsafeEntity>> => {
    const result = await httpService.get<FailsafeEntity>(
      `${failsafeService.url}/${id}`
    );
    return result;
  },

  create: async (
    payload: FailsafePayloadEntity
  ): Promise<CreateResponse<FailsafeEntity>> => {
    const result = await httpService.post<FailsafeEntity>(
      `${failsafeService.url}`,
      payload
    );
    return result;
  },

  update: async (
    id: string,
    payload: FailsafePayloadEntity
  ): Promise<UpdateResponse<FailsafeEntity>> => {
    const result = await httpService.put<FailsafeEntity, FailsafePayloadEntity>(
      `${failsafeService.url}/${id}`,
      payload
    );
    return result;
  },
  delete: async (id: string) => {
    const result = await httpService.del(`${failsafeService.url}/${id}`);
    return result;
  }
};

export default failsafeService;

import { ErrorValidation } from 'types/error-types';
import { InputChangeEvent } from 'types/common-types';
import { useEffect, useState } from 'react';
import CancelButton from 'components/form/button-cancel';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import SaveButton from 'components/form/button-save';
import StackRowWithDivider from 'components/stack/stack-row-with-divider';
import useRouteName from 'pages/route-outlet-context';
import ErrorComponent from 'components/errors/error-component';
import { isEmpty } from 'helpers/misc-helper';
import { FailsafeEntity } from 'types/failsafe-types';
import initialFailsafe from 'state/failsafe/initial-failsafe';
import failsafeService from 'services/failsafe-sercvice';
import { CreateResponse } from 'types/api-response-types';
import UnitItem from 'components/form/unit-item';
import PaperBoxHeader from 'components/paper-box/paper-box-header';
import ModalHeader from 'components/modal/modal-header';
import { Divider } from '@mui/material';
import { validateFailsafe } from 'helpers/validation/failsafe-helper';
import { toastError, toastSuccess } from 'event/toast-event';
import FormContainer from 'components/form/container';
import UnitText from 'components/form/unit-text';
import fieldLabel from 'assets/constants/fieldLabel';
import UnitEmpty from 'components/form/unit-empty';

const FailSafeCreate = ({
  routeTag,
  failsafe_id,
  onCancel = () => {}
}: {
  routeTag: string;
  failsafe_id?: string | null;
  onCancel?: (reload: boolean) => void;
}) => {
  const { setRouteName } = useRouteName();
  const [failsafe, setFailsafe] = useState<FailsafeEntity>(initialFailsafe);
  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: InputChangeEvent) => {
    setFailsafe((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const loadFailSafe = async (failsafe_id: string) => {
    const response = await failsafeService.get(failsafe_id);

    if (response.isError) {
      setErrorMessage(response.errorMessage.message);
      return;
    }

    setFailsafe(response.data);
  };

  const handleSubmit = async (): Promise<void> => {
    const { status, errors } = validateFailsafe(failsafe);
    let response: CreateResponse<FailsafeEntity>;

    setValidation(errors);

    setIsLoading(true);

    if (!status) {
      setIsLoading(false);
      return;
    }

    if (failsafe_id) {
      response = await failsafeService.update(failsafe_id, failsafe);
    } else {
      response = await failsafeService.create(failsafe);
    }

    setIsLoading(false);

    if (response.isValidationError) {
      setValidation(response.validationMessage);
      return;
    }

    if (response.isError) {
      toastError(response.errorMessage.message);
      return;
    }

    if (response.isSuccess) {
      toastSuccess('Failsafe saved successfully');
      if (onCancel) onCancel(true);
    }
  };

  useEffect(() => {
    setRouteName(routeTag);
  });

  useEffect(() => {
    if (failsafe_id) loadFailSafe(failsafe_id);
  }, []);

  return (
    <PaperBox>
      <UnitItem grid={{ xs: 12, sm: 12 }}>
        <PaperBoxHeader
          value={
            <ModalHeader
              title={
                routeTag === 'record-add'
                  ? 'Add Default Values'
                  : 'Edit Default Values'
              }
              onClose={() => {
                onCancel(false);
                setFailsafe(initialFailsafe);
              }}
            />
          }
          sx={{ border: 'none' }}
        />
        <Divider style={{ margin: '10px 0' }} />
      </UnitItem>
      <PaperBoxContent>
        {isEmpty(errorMessage) ? (
          <>
            <FormContainer>
              <UnitText
                label={fieldLabel.name}
                name="name"
                value={failsafe.name ?? ''}
                onChange={handleChange}
                error={validation['name'] ?? ''}
                required
              />

              <UnitText
                label={fieldLabel.value}
                name="value"
                value={failsafe.value ?? ''}
                error={validation['value'] ?? ''}
                onChange={handleChange}
                required
                multiline
                rows={5}
              />

              <UnitText
                label={fieldLabel.category}
                name="category"
                value={failsafe.category ?? ''}
                onChange={handleChange}
                error={validation['category'] ?? ''}
                required
              />

              <UnitText
                label={`${fieldLabel.subCategory} 1`}
                name="subcategory_1"
                value={failsafe.subcategory_1 ?? ''}
                onChange={handleChange}
                error={validation['subcategory_1'] ?? ''}
                required
              />

              <UnitEmpty />

              <UnitText
                label={`${fieldLabel.subCategory} 2`}
                name="subcategory_2"
                value={failsafe.subcategory_2 ?? ''}
                onChange={handleChange}
                error={validation['subcategory_2'] ?? ''}
                required
              />

              <UnitText
                label={fieldLabel.description}
                name="description"
                value={failsafe.description ?? ''}
                onChange={handleChange}
                multiline
                rows={5}
                grid={{ xs: 12, sm: 12 }}
              />
            </FormContainer>

            <StackRowWithDivider>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
              <CancelButton
                onClick={() => {
                  onCancel(false);
                  setFailsafe(initialFailsafe);
                }}
              />
            </StackRowWithDivider>
          </>
        ) : (
          <ErrorComponent message={errorMessage} />
        )}
      </PaperBoxContent>
    </PaperBox>
  );
};

export default FailSafeCreate;
